import { useState } from 'react';

import TextInput from '../../inputs/form-inputs/TextInput';
import SwitchInput from '../../inputs/form-inputs/SwitchInput';

function PropSetting({ name, type, value, defaultValue, isRequired, allowMultipleOptions, options, setComponentProps }) {
  if (type === 'string') {
    if (options && options.length) {
      return (
        <div className="d-flex flex-column gap-2">
          <label className="text-muted">{name}</label>
          <select value={value} onChange={(e) => setComponentProps((p) => ({ ...p, [name]: e.target.value }))}>
            <option value="" disabled>
              Select an option
            </option>
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      );
    }

    return (
      <div className="d-flex flex-column gap-2">
        <label className="text-muted">{name}</label>
        <TextInput value={value} onChange={(e) => setComponentProps((p) => ({ ...p, [name]: e.target.value }))} />
      </div>
    );
  }

  if (type === 'boolean') {
    return (
      <div className="d-flex flex-column gap-2">
        <label className="text-muted">{name}</label>
        <SwitchInput checked={value} onChange={(e) => setComponentProps((p) => ({ ...p, [name]: e.target.checked }))} />
      </div>
    );
  }

  if (type === 'array') {
    return (
      <div className="d-flex flex-column gap-2">
        <label className="text-muted">{name}</label>
        <select
          onChange={(e) => {
            console.log('Triggered');
            console.log(e.target.value);
            console.log(options.find((o) => o.label === e.target.value).value);
            setComponentProps((p) => ({
              ...p,
              [name]: options.find((o) => o.label === e.target.value).value,
            }));
          }}
        >
          <option value="" disabled>
            Select an option
          </option>
          {options.map((option, index) => (
            <option key={index} value={option.label}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  }

  return <div>Not supported data type.</div>;
}

export default function ComponentSettingsModal({ dispatch, component, definition }) {
  const [componentProps, setComponentProps] = useState(component.props);

  const handleSaveComponent = () => {
    dispatch({ type: 'update_individual_content', payload: { ...component, props: componentProps } });
  };

  return (
    <div
      className="modal fade"
      id={`component-${component.id}-settings-modal`}
      tabIndex={-1}
      aria-labelledby={`component-${component.id}-settings-label`}
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="d-flex w-100 justify-content-between align-items-center">
              <h1 className="modal-title fs-3" id={`component-${component.id}-settings-label`}>
                Configure the component
              </h1>
              <button type="button" className="btn btn-danger btn-sm" data-bs-dismiss="modal">
                <i className="fe fe-x" />
              </button>
            </div>
          </div>
          <div className="modal-body">
            <div className="container">
              {definition?.propSchema?.map((prop, index) => (
                <PropSetting key={index} value={componentProps[prop.name]} setComponentProps={setComponentProps} {...prop} />
              ))}
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={handleSaveComponent} data-bs-dismiss="modal">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
