import { useEffect } from "react";

import DateInput from "../../../../inputs/form-inputs/DateInput";
import { formatDateTime } from "../../../utils/DateFormatters";

const scheduleOptions = [
  { value: "now", label: "Now", icon: "fe fe-target" },
  { value: "scheduled", label: "Later", icon: "fe fe-calendar" },
];

export default function PostSchedulingStep(props) {
  const { formState, formDispatch, post } = props;

  const today = new Date();

  const handleScheduledAt = (date) => {
    const dateISOString = new Date(date).toISOString();
    formDispatch({ type: "update_data", payload: { scheduled_at: dateISOString } });
  };

  useEffect(() => {
    if (!formState.data.scheduled_option) {
      formDispatch({ type: "update", payload: { isInvalid: true, error: "Please select a scheduled option to continue." } });
      return;
    }

    if (formState.data.scheduled_option === "scheduled" && !formState.data.scheduled_at) {
      formDispatch({ type: "update", payload: { isInvalid: true, error: "Please select a scheduled date and time to continue." } });
      return;
    }

    if (post) {
      if (post.scheduled_option === "scheduled" && formState.data.scheduled_option === "now") {
        formDispatch({
          type: "update",
          payload: { isInvalid: false, error: `Post is now scheduled to post immediately. Click "Save" for changes to take effect.` },
        });
        return;
      }

      if (post.scheduled_at !== formState.data.scheduled_at) {
        formDispatch({
          type: "update",
          payload: { isInvalid: false, error: `Post schedule has now changed. Click "Save" for changes to take effect.` },
        });
        return;
      }
    }

    // Saves to API on every value change
    formDispatch({ type: "update", payload: { isSaving: false, status: "", error: "", isInvalid: false } });
  }, [formState.data.scheduled_option, formState.data.scheduled_at]);

  return (
    <div className="card-body">
      <h2>When would you like to post this?</h2>
      <div className="d-flex flex-column gap-4">
        <div className="d-flex gap-3">
          {scheduleOptions.map((o) => (
            <label key={o.value} className="col">
              <input
                type="radio"
                className="card-input-element"
                id={o.value}
                name="schedule_option"
                value={o.value}
                onChange={(e) => {
                  formDispatch({
                    type: "update_data",
                    payload: { scheduled_option: e.target.value, scheduled_at: e.target.value === "now" ? null : post?.scheduled_at },
                  });
                }}
                checked={formState.data.scheduled_option === o.value}
                disabled={formState.isDisabled}
              />
              <div className={`card card-input ${formState.isDisabled ? "disabled" : ""}`}>
                <div className="card-body">
                  <div className="d-flex flex-column justify-content-center gap-2">
                    <i className={o.icon} />
                    <p className="mb-0">{o.label}</p>
                  </div>
                </div>
              </div>
            </label>
          ))}
        </div>
        {formState.data.scheduled_option === "scheduled" ? (
          <div className="input-group mb-3">
            <span className="input-group-text">
              <i className="fe fe-calendar" />
            </span>
            <DateInput
              id="scheduled_at"
              className="form-control"
              data-enable-time
              options={{
                minDate: today,
                minuteIncrement: 1,
              }}
              placeholder="Select a date and time"
              defaultValue={formState.data.scheduled_at}
              onChange={([date]) => handleScheduledAt(date)}
              disabled={formState.isDisabled}
            />
          </div>
        ) : null}

        <div className="d-flex justify-content-center gap-3">
          <p className="mb-0">Scheduled for:</p>
          <code className="fs-4 mb-0">
            {formState.data.scheduled_option === "now" && !formState.data.scheduled_at
              ? "Now"
              : !formState.data.scheduled_at
              ? "-"
              : formatDateTime(formState.data.scheduled_at)}
          </code>
        </div>
      </div>
    </div>
  );
}