import { formatDateTime } from "../../utils/DateFormatters";

export default function PostsTable(props) {
  const { posts, endpoints } = props;

  const handleDeletePost = (id) => {
    // Confirm the user wants to delete the post
    if (!window.confirm("Are you sure you want to delete this post?")) return;

    fetch(`${endpoints.socialPostsAPIUrl}/${id}.json`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json", credentials: "same-origin" },
    })
      .then((response) => {
        if (response.ok) {
          // If the request was successful, redirect to postDashboardUrl
          window.location.reload();
        } else {
          // If the request was not successful, log the error to the console
          console.error("Failed to delete post:", response);
        }
      })
      .catch((error) => {
        // If the request was not successful, log the error to the console
        console.error("Failed to delete post:", error);
      });
  };

  const generateEditPostPath = (id) => {
    return ` /_sf/admin/social/posts/${id}/edit`;
  };

  return (
    <div className="card card-primary overflow-hidden">
      <table className="table table-sm mb-0 border-0">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Posters</th>
            <th scope="col">Created At</th>
            <th scope="col">Last Updated</th>
            <th scope="col">Scheduled For</th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {posts.map((post, index) => (
            <tr key={post.id}>
              <th scope="row">{index + 1}</th>
              <td>
                <a href={`/_sf/admin/social/posts/${post.id}`}>{post.name}</a>
              </td>
              <td>{post.social_post_jobs.length}</td>
              <td className="text-sm">{formatDateTime(post.created_at)}</td>
              <td className="text-sm">{formatDateTime(post.updated_at)}</td>
              <td className="text-sm">{formatDateTime(post.scheduled_at)}</td>
              <td className="text-sm text-muted">{post.status ? post.status.charAt(0).toUpperCase() + post.status.slice(1) : "Draft"}</td>
              <td className="d-flex gap-2 align-items-center">
                <a className="btn btn-info" href={generateEditPostPath(post.id)}>
                  Edit
                </a>
                <button className="btn btn-danger" onClick={() => handleDeletePost(post.id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}