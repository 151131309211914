import { useReducer } from 'react';

import Builder from '../_components/Builder';

const initialState = {
  isLoading: false,
  isError: false,
  status: '',
  error: null,
  data: {
    title: '',
    metadata: {},
    content: [],
    url_slug: '',
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return { ...state, ...action.payload };
    case 'update_data':
      return { ...state, data: { ...state.data, ...action.payload } };
    case 'update_individual_content':
      return { ...state, data: { ...state.data, content: state.data.content.map((item) => (item.id === action.payload.id ? action.payload : item)) } };
    case 'add_content':
      return { ...state, data: { ...state.data, content: [...state.data.content, action.payload] } };
    case 'remove_content':
      return { ...state, data: { ...state.data, content: state.data.content.filter((item) => item.id !== action.payload.id) } };
    default:
      return state;
  }
};

export default function NewDynamicPage({ componentDefinitions, endpoints }) {
  const { indexUrl, createUrl, previewSource } = endpoints;

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleSave = () => {
    fetch(`${createUrl}.json`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'same-origin',
      },
      body: JSON.stringify(state.data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to save page');
        }
        window.location.replace(indexUrl);
      })
      .catch((error) => {
        dispatch({ type: 'update', payload: { isError: true, error: JSON.stringify(error) } });
      });
  };

  return <Builder {...{ state, dispatch, handleSave, previewSource, componentDefinitions }} />;
}
