import { Fragment } from 'react';

import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';

import SortableComponent from './SortableComponent';
import ComponentSelectionModal from './ComponentSelectionModal';
import ComponentSettingsModal from './ComponentSettingsModal';

export default function SiteEditorSidebar({ state, dispatch, componentDefinitions }) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const content = state.data.content;

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = content.findIndex((c) => c.id === active.id);
      const newIndex = content.findIndex((c) => c.id === over.id);

      // Updates the category schema with the new order of the fields
      dispatch({ type: 'update_data', payload: { content: arrayMove(content, oldIndex, newIndex) } });
    }
  };

  return (
    <div className="d-flex flex-column gap-2">
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={content} strategy={verticalListSortingStrategy}>
          {content.map((component, index) => {
            const definition = componentDefinitions.find((c) => c.name === component.name);
            const isPropsEditable = definition?.propSchema?.length > 0;

            return (
              <Fragment key={index}>
                <SortableComponent key={component.id} dispatch={dispatch} component={component} isPropsEditable={isPropsEditable} />
                <ComponentSettingsModal {...{ dispatch, component, definition }} />
              </Fragment>
            );
          })}
        </SortableContext>
      </DndContext>
      <div className="add_component_container">
        <div className="d-flex">
          <div className="vr vertical_line" />
        </div>
        <div className="button_container">
          <button className="btn btn-primary rounded-circle" style={{ height: 40, width: 40 }} data-bs-toggle="modal" data-bs-target="#componentSelectionModal">
            <i className="fe fe-plus" />
          </button>
        </div>
      </div>
      <ComponentSelectionModal {...{ dispatch, componentDefinitions }} />
    </div>
  );
}
