import { useEffect, useRef } from 'react';
import SiteEditorSidebar from './SiteEditorSidebar';

export default function Builder({ state, dispatch, handleSave, previewSource, componentDefinitions }) {
  const iFrameRef = useRef(null);

  const handleLoad = () => {
    const content = JSON.stringify(state.data.content);
    iFrameRef.current.contentWindow.postMessage({ type: 'SET_CONTENT', message: content }, '*');
  };

  useEffect(() => {
    const iframe = iFrameRef.current;
    if (iframe) {
      iframe.addEventListener('load', handleLoad);
      handleLoad();
    }

    return () => {
      if (iframe) iframe.removeEventListener('load', handleLoad);
    };
  }, [state.data.content]);

  return (
    <div className="d-flex flex-column gap-4 dynamic-edit-page">
      <div className="d-flex justify-content-between gap-3 top-input">
        <input
          type="text"
          className="form-control title-input"
          placeholder="Page Title..."
          value={state.data.title}
          onChange={(e) => dispatch({ type: 'update_data', payload: { title: e.target.value } })}
        />
        <input
          type="text"
          className="form-control"
          placeholder="blog/my-first-post"
          value={state.data.url_slug}
          onChange={(e) => dispatch({ type: 'update_data', payload: { url_slug: e.target.value } })}
        />
        <button className="btn btn-primary" onClick={handleSave}>
          Save
        </button>
      </div>
      <div className="row">
        <div className="col-3">
          <SiteEditorSidebar {...{ state, dispatch, componentDefinitions }} />
        </div>
        <div className="col-9">
          <iframe ref={iFrameRef} className="w-100 border-1" src={previewSource} style={{ height: 700 }} />
        </div>
      </div>
    </div>
  );
}
