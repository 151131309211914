export default function ConfirmationDialog({
  id,
  icon = 'fe-alert-circle',
  title,
  message,
  confirmButtonText = 'Yes',
  confirmButtonClass = 'btn-danger',
  cancelButtonText = 'No',
  cancelButtonClass = 'btn-secondary',
  onConfirm,
}) {
  return (
    <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-labelledby={id} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{title}</h4>
          </div>
          <div className="modal-body py-4 px-3">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <i className={`fe ${icon} pb-3`} style={{ fontSize: 72 }}></i>
              <p className="mb-0 text-center">{message}</p>
            </div>
          </div>
          <div className="modal-footer justify-content-between">
            <button type="button" className={`btn ${cancelButtonClass}`} data-bs-dismiss="modal">
              {cancelButtonText}
            </button>
            <button type="button" className={`btn ${confirmButtonClass}`} onClick={onConfirm}>
              {confirmButtonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
