import { useEffect, useReducer, useState } from 'react';
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion';

import TextInput from '../../inputs/form-inputs/TextInput';

function DynamicPageCard({ dynamicPage: dp, onClick }) {
  return (
    <motion.div
      layoutId={`card-container-${dp.id}`}
      className="dynamic-page-card"
      onClick={() => onClick(dp)}
      initial={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="d-flex flex-column">
        <h3>{dp.title}</h3>
        <p className="text-muted">{dp.url_slug}</p>
      </div>
    </motion.div>
  );
}

function DynamicPageCardFullscreen({ state, handleClose, handleDelete, editUrl, currentDomain }) {
  const { isLoading, selectedPage } = state;
  const [is404, setIs404] = useState(false);

  useEffect(() => {
    // Check if the page exists
    fetch(`${currentDomain}${selectedPage.url_slug}`)
      .then((res) => {
        if (!res.ok) {
          throw new Error('Page not found.');
        }
      })
      .catch((error) => {
        console.log(error);
        setIs404(true);
      });
  }, [selectedPage.url_slug, currentDomain]);

  return (
    <>
      <motion.div className="backdrop" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} onClick={handleClose} />
      <motion.div layoutId={`card-container-${selectedPage.id}`} className="dynamic-page-card-fullscreen">
        <motion.div className="dynamic-page-card-fullscreen-content">
          <motion.div className="dynamic-page-card-fullscreen-content-header">
            <motion.button className="btn btn-sm" onClick={handleClose}>
              <i className="fe fe-x" />
            </motion.button>
          </motion.div>
          <motion.div className="dynamic-page-card-fullscreen-content-body">
            <motion.div className="d-flex justify-content-between">
              <motion.div className="d-flex flex-column">
                <motion.h1 className="mb-0">{selectedPage.title}</motion.h1>
                <motion.p className="text-muted mb-0">{selectedPage.url_slug}</motion.p>
              </motion.div>
              <motion.div>
                <motion.p className="text-muted mb-0">Components: {selectedPage.content?.length}</motion.p>
              </motion.div>
            </motion.div>
            {is404 ? (
              <motion.div className="border rounded w-100 h-100 d-flex justify-content-center align-items-center">
                <motion.p>Page not found in live site.</motion.p>
              </motion.div>
            ) : (
              <motion.iframe className="dynamic-page-card-fullscreen-content-preview" src={`${currentDomain}${selectedPage.url_slug}`} />
            )}
          </motion.div>
          <motion.div className="dynamic-page-card-fullscreen-content-footer">
            {isLoading ? (
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            ) : (
              <>
                <motion.button className="btn btn-danger" onClick={() => handleDelete(selectedPage.id)}>
                  Delete
                </motion.button>
                <motion.a className="btn btn-primary" href={editUrl.replace(':id', selectedPage.id)}>
                  Edit
                </motion.a>
              </>
            )}
          </motion.div>
        </motion.div>
      </motion.div>
    </>
  );
}

const initialState = {
  isLoading: false,
  isError: false,
  status: '',
  selectedPage: null,
  filteredPages: [],
  sortMode: null,
};

function reducer(state, action) {
  switch (action.type) {
    case 'SET_SELECTED_PAGE':
      return { ...state, selectedPage: action.payload };
    case 'SET_FILTERED_PAGES':
      return { ...state, filteredPages: action.payload };
    case 'SET_SORT_MODE':
      return { ...state, sortMode: action.payload };
    case 'TOGGLE_IS_LOADING':
      return { ...state, isLoading: !state.isLoading };
    case 'TOGGLE_IS_ERROR':
      return { ...state, isError: !state.isError };
    case 'SET_STATUS':
      return { ...state, status: action.payload };
    default:
      return state;
  }
}

export default function DynamicPagesDashboard({ dynamicPages, endpoints }) {
  const { currentDomain, deleteUrl, editUrl } = endpoints;

  const [state, dispatch] = useReducer(reducer, { ...initialState, filteredPages: dynamicPages });

  const sortIconStyle = {
    transform: state.sortMode === 'DESC' ? 'rotate(180deg)' : '',
    transition: 'transform 200ms ease',
  };

  const handleClose = () => {
    dispatch({ type: 'SET_SELECTED_PAGE', payload: null });
  };

  const handleDelete = (id) => {
    dispatch({ type: 'TOGGLE_IS_LOADING' });

    fetch(`${deleteUrl.replace(':id', id)}.json`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'same-origin',
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to delete page.');
        }
        window.location.reload();
      })
      .catch((error) => {
        dispatch({ type: 'TOGGLE_IS_LOADING' });
        dispatch({ type: 'TOGGLE_IS_ERROR' });
        dispatch({ type: 'SET_STATUS', payload: `Some error occured: [${JSON.stringify(error)}]` });
      });
  };

  const handleSearch = (e) => {
    const search = e.target.value;
    dispatch({
      type: 'SET_FILTERED_PAGES',
      payload: dynamicPages.filter((dp) => dp.title.toLowerCase().includes(search.toLowerCase())),
    });
  };

  const handleSortOnClick = () => {
    console.log(dynamicPages);
    switch (state.sortMode) {
      case null:
        dispatch({ type: 'SET_SORT_MODE', payload: 'ASC' });
        dispatch({
          type: 'SET_FILTERED_PAGES',
          payload: [...state.filteredPages].sort((a, b) => a.title.localeCompare(b.title)),
        });
        break;
      case 'ASC':
        dispatch({ type: 'SET_SORT_MODE', payload: 'DESC' });
        dispatch({
          type: 'SET_FILTERED_PAGES',
          payload: [...state.filteredPages].sort((a, b) => b.title.localeCompare(a.title)),
        });
        break;
      case 'DESC':
        dispatch({ type: 'SET_SORT_MODE', payload: null });
        console.log(dynamicPages);
        dispatch({ type: 'SET_FILTERED_PAGES', payload: dynamicPages });
        break;
    }
  };

  return (
    <LayoutGroup>
      <div className="pb-4 w-50 d-flex flex-column gap-3">
        <TextInput label="Search" placeholder="Search for a page..." onChange={handleSearch} />
        <div>
          <button type="button" className="btn btn-outline-primary btn-sm d-flex gap-2" onClick={handleSortOnClick}>
            {!state.sortMode ? <i className="fe fe-minus" /> : <i className="fe fe-corner-right-down" style={sortIconStyle} />}
            <span>Sort</span>
          </button>
        </div>
      </div>
      <div className="grid">
        {state.filteredPages.length ? (
          state.filteredPages.map((dp) => <DynamicPageCard key={dp.id} dynamicPage={dp} onClick={() => dispatch({ type: 'SET_SELECTED_PAGE', payload: dp })} />)
        ) : (
          <div className="w-100 d-flex justify-content-center align-items-center" style={{ height: 400 }}>
            <p className="text-muted">No pages found</p>
          </div>
        )}
      </div>
      <AnimatePresence>{state.selectedPage && <DynamicPageCardFullscreen {...{ state, handleClose, handleDelete, editUrl, currentDomain }} />}</AnimatePresence>
    </LayoutGroup>
  );
}
