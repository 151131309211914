import { formatDateTime } from "../../utils/DateFormatters";

export default function AccountInfo(props) {
  const { linkedin_user } = props;

  return (
    <div>
      <div className="card border rounded">
        <div className="d-flex gap-4 p-4">
          <div>
            <img style={{maxWidth: "32px"}} src={linkedin_user.picture} className="rounded-circle" />
          </div>
          <div>
            <h1>{linkedin_user.name}</h1>
            <p>{linkedin_user.phone}</p>
            <div className="d-flex gap-3">
              <span className="text-muted">Last modified: {formatDateTime(linkedin_user.updated_at)}</span>
              <span className="text-muted">Added on: {formatDateTime(linkedin_user.created_at)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}