import { useReducer, useEffect } from 'react';
import { TagsInput } from 'react-tag-input-component';

import DropdownInput from '../inputs/form-inputs/DropdownInput';
import SwitchInput from '../inputs/form-inputs/SwitchInput';

const initialState = (form) => ({
  isInvalid: false,
  isLoading: false,
  isError: false,
  status: '',
  error: '',
  data: {
    submission_email_address_extras: form.submission_email_address_extras || [],
    submission_email_address: form.submission_email_address || '',
  },
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return { ...state, ...action.payload };
    case 'update_data':
      return { ...state, data: { ...state.data, ...action.payload } };
    default:
      return state;
  }
};

export default function FormRecipients(props) {
  const { form, formFields } = props;

  const [state, dispatch] = useReducer(reducer, initialState(form));

  const handleUpdateRule = (index, payload) => {
    dispatch({
      type: 'update_data',
      payload: {
        submission_email_address_extras: state.data.submission_email_address_extras.map((rule, i) => {
          if (i === index) {
            return { ...rule, ...payload };
          }
          return rule;
        }),
      },
    });
  };

  const handleRemoveRule = (index) => {
    dispatch({
      type: 'update_data',
      payload: {
        submission_email_address_extras: state.data.submission_email_address_extras.filter((_, i) => i !== index),
      },
    });
  };

  const handleAddRule = () => {
    dispatch({
      type: 'update_data',
      payload: {
        submission_email_address_extras: [...state.data.submission_email_address_extras, { field_key: '', field_value: '', recipients: [] }],
      },
    });
  };

  const updateFormRules = () => {
    dispatch({ type: 'update', payload: { isLoading: true } });

    const body = {
      ...state.data,
      //Filter out any empty rules
      submission_email_address_extras: state.data.submission_email_address_extras.filter((rule) => rule.field_key && rule.recipients.length),
    };

    fetch(`/_sf/api/v1/cms/forms/${form.id}.json`, { method: 'PUT', body: JSON.stringify(body), headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Response was not ok');
        }
        dispatch({ type: 'update', payload: { isLoading: false, status: 'Saved!' } });

        // Refresh the page
        window.location.reload();
      })
      .catch((error) => {
        dispatch({
          type: 'update',
          payload: { isLoading: false, isError: true, error: `An error occurred while saving the form rules - [${JSON.stringify(error)}]` },
        });
      });
  };

  useEffect(() => {
    dispatch({ type: 'update', payload: { status: '' } });
  }, [state.data.submission_email_address_extras, state.data.submission_email_address]);

  return (
    <div className="d-flex flex-column" style={{ maxWidth: 1100 }}>
      <div className="d-flex justify-content-end align-items-center gap-3 mb-3">
        {state.isError && <label className="text-danger">{state.error}</label>}
        <label className="text-muted">{state.status}</label>
        <button className="btn btn-primary rounded" onClick={updateFormRules} disabled={form.isLoading} style={{ width: 74, height: 41 }}>
          <div className="d-flex align-items-center justify-content-center gap-2">
            {state.isLoading ? (
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              'Save'
            )}
          </div>
        </button>
      </div>
      {state.data.submission_email_address_extras.length ? (
        <div className="rules_container">
          <div className="d-flex flex-column gap-3">
            {state.data.submission_email_address_extras.map((rule, index) => (
              <Rule key={index} {...{ index, rule, formFields, handleUpdateRule, handleRemoveRule }} />
            ))}
          </div>
          <div className="add_rule_container">
            <div className="d-flex">
              <div className="vr vertical_line" />
            </div>
            <div className="button_container">
              <button className="btn btn-primary rounded-circle" onClick={handleAddRule} style={{ height: 40, width: 40 }}>
                <i className="fe fe-plus" />
              </button>
            </div>
          </div>
          <div className="base_rule">
            <div className="d-flex">
              <div className="vr vertical_line" />
            </div>
            <BaseRule {...{ state, dispatch }} />
          </div>
        </div>
      ) : (
        <div className="empty_rule_container">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <p className="text-muted">Add e-mail recipients</p>
            <button className="btn btn-primary rounded-circle" onClick={handleAddRule} style={{ height: 40, width: 40 }}>
              <i className="fe fe-plus" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

function Rule(props) {
  const { index, rule, formFields, handleUpdateRule, handleRemoveRule } = props;

  const ruleFieldKeys = formFields.map((field) => ({ value: field.key, label: field.label, type: field.type }));

  const ruleFieldValues = formFields.find((field) => field.key === rule.field_key)?.values?.map((each) => ({ value: each.value, label: each.label })) || [];

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return (
    <div className="card mb-0">
      <div className="d-flex flex-column gap-3 p-3">
        <div className="d-flex flex-column gap-2">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <label>Rule #{index + 1}</label>
            <button className="btn btn-danger btn-sm" onClick={() => handleRemoveRule(index)}>
              <i className="fe fe-x" />
            </button>
          </div>

          <div className="d-flex justify-content-center align-items-center gap-2">
            <DropdownInput
              placeholder="Select a form input field..."
              className="w-100"
              formattedDropdownValues={ruleFieldKeys}
              value={rule.field_key}
              onChange={(option) => handleUpdateRule(index, { field_key: option.value, field_value: '' })}
            />
            {formFields.find((field) => field.key === rule.field_key)?.type === 'checkbox' ? (
              <div className="w-100">
                <SwitchInput
                  label={Boolean(rule.field_value) ? 'Checked' : 'Unchecked'}
                  value={rule.field_value}
                  checked={rule.field_value}
                  onChange={(e) => handleUpdateRule(index, { field_value: Boolean(e.target.checked) })}
                />
              </div>
            ) : (
              <DropdownInput
                placeholder="Select a value..."
                className="w-100"
                formattedDropdownValues={ruleFieldValues}
                value={rule.field_value}
                onChange={(option) => handleUpdateRule(index, { field_value: option.value })}
              />
            )}
          </div>
        </div>

        <div className="d-flex flex-column gap-2">
          <label className="text-muted">Sends to:</label>
          <TagsInput
            className="border-start-0"
            placeholder="(Enter recipent addresses)"
            value={rule.recipients}
            onChange={(values) => handleUpdateRule(index, { recipients: values })}
            beforeAddValidate={(value) => emailRegex.test(value)}
            separators={['Enter', ',', ' ']}
            isEditOnRemove={true}
            onBlur={(e) => {
              // Adds email address to the list if it doesn't already exist
              if (!rule.recipients.includes(e.target.value) && e.target.value && emailRegex.test(e.target.value)) {
                handleUpdateRule(index, { recipients: [...rule.recipients, e.target.value] });
              }

              //Clear target value on blur
              e.target.value = '';
            }}
          />
          <span className="text-muted small">Press enter after entering each email address</span>
        </div>
      </div>
    </div>
  );
}

function BaseRule(props) {
  const { state, dispatch } = props;
  return (
    <div className="card mb-0 w-100">
      <div className="d-flex flex-column gap-3 p-3">
        <label className="text-muted">Always send to (separated by ,):</label>
        <div className="input-group mb-3">
          <span className="input-group-text">
            <i className="fe fe-mail" />
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="Enter e-mail addresses here..."
            value={state.data.submission_email_address}
            onChange={(e) => dispatch({ type: 'update_data', payload: { submission_email_address: e.target.value } })}
          />
        </div>
      </div>
    </div>
  );
}
