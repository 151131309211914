import Card from '../../card/Card';
import SortableItem from '../../dnd-kit/SortableItem/SortableItem';

export default function SortableComponent({ component, dispatch, isPropsEditable }) {
  const handleRemoveComponent = (componentId) => {
    dispatch({ type: 'remove_content', payload: { id: componentId } });
  };

  return (
    <SortableItem key={component.id} id={component.id}>
      <Card bodyClass="p-4">
        <div className="w-100 d-flex justify-content-between align-items-center">
          <h3 className="mb-0">{component.name}</h3>
          <div className="d-flex gap-2">
            {isPropsEditable ? (
              <button className="btn btn-sm" data-bs-toggle="modal" data-bs-target={`#component-${component.id}-settings-modal`}>
                <i className="fe fe-edit" />
              </button>
            ) : null}
            <button className="btn btn-danger btn-sm" onClick={() => handleRemoveComponent(component.id)}>
              <i className="fe fe-trash" />
            </button>
          </div>
        </div>
      </Card>
    </SortableItem>
  );
}
