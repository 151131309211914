import { v4 as uuidv4 } from 'uuid';
import { useState } from 'react';

function ComponentButton({ c, componentDefinitions, setSelected }) {
  const handleSelected = (e) => {
    const component = componentDefinitions.find((component) => component.name === e.target.value);
    setSelected(component);
  };

  return (
    <label className="col">
      <input type="radio" className="card-input-element" id={c.name} name="component_option" value={c.name} onChange={handleSelected} />
      <div className="card card-input">
        <div className="card-body">{c.name}</div>
      </div>
    </label>
  );
}

export default function ComponentSelectionModal({ dispatch, componentDefinitions }) {
  const [selected, setSelected] = useState(null);

  // Generate props using reducer
  const generateProps = (propSchema) => {
    return propSchema.reduce((acc, prop) => {
      acc[prop.name] = prop.defaultValue ?? '';
      return acc;
    }, {});
  };

  const handleAddComponent = () => {
    const { propSchema, ...rest } = selected;

    const component = componentDefinitions.find((component) => component.name === selected.name);

    const props = component.propSchema ? generateProps(component.propSchema) : {};

    dispatch({ type: 'add_content', payload: { ...rest, id: uuidv4(), ...(props && { props }) } });
  };

  return (
    <div className="modal fade" id="componentSelectionModal" tabIndex={-1} aria-labelledby="componentSelectionModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="d-flex w-100 justify-content-between align-items-center">
              <h1 className="modal-title fs-5" id="componentSelectionModalLabel">
                Select a component
              </h1>
              <button type="button" className="btn btn-danger btn-sm" data-bs-dismiss="modal">
                <i className="fe fe-x" />
              </button>
            </div>
          </div>
          <div className="modal-body">
            <div className="container">
              <div className="row">
                {componentDefinitions && componentDefinitions.length ? (
                  componentDefinitions.map((c, index) => (
                    <ComponentButton key={index} c={c} componentDefinitions={componentDefinitions} setSelected={setSelected} />
                  ))
                ) : (
                  <div className="text-center">No components found.</div>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={handleAddComponent} data-bs-dismiss="modal">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
